import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import {sanitizeText} from 'utils/html';
import s from './Breadcrumbs.module.scss';

const Breadcrumbs = ({ items, inHero }) => {
    const {t} = useTranslation();

    const classes = classNames(
        [s['Breadcrumbs']],
        {[s['Breadcrumbs--InHero']]: inHero},
    );
    const home = {
        url: '/',
        title: t('breadcrumbs.home'),
        isCurrent: false,
    };
    return (
        <div className={classes}>
            <div className={s['Breadcrumbs__Wrap']}>
                <ul className={s['Breadcrumbs__List']}>
                    {[home, ...items].map((item, index) =>
                        <Item key={index} {...item} />
                    )}
                </ul>
            </div>
        </div>
    );
};

Breadcrumbs.propTypes = {
    items: PropTypes.array,
    inHero: PropTypes.bool,
};

Breadcrumbs.defaultProps = {
    items: [],
    inHero: false,
};

const Item = ({url, title, isCurrent}) => {
    return (
        <li className={s['Breadcrumbs__Item']}>
            {isCurrent ? (
                <span
                    className={classNames(s['Breadcrumbs__Link'], s['Breadcrumbs__Link--Current'])}
                    dangerouslySetInnerHTML={{__html: sanitizeText(title)}}
                />
            ) : (
                <a
                    href={url}
                    className={s['Breadcrumbs__Link']}
                    dangerouslySetInnerHTML={{__html: sanitizeText(title)}}
                />
            )}
        </li>
    );
};

Item.propTypes = {
    url: PropTypes.string,
    title: PropTypes.string,
    isCurrent: PropTypes.bool,
};

Item.defaultProps = {
    url: '',
    title: '',
    isCurrent: false,
};

export default Breadcrumbs;
