import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Base from 'Layouts/Base';
import Breadcrumbs from 'Components/Breadcrumbs';
import Hero from 'Components/Hero';
import Highlights from 'Components/Highlights';
import Modules from 'Components/Modules';
import s from './LandingSearch.module.scss';

const LandingSearch = ({breadcrumbs, hero, highlights, modules}) => {

    // ImageBlock, GutenbergModule, Search and FormModule are added to Modules
    return (
        <div className={s['LandingSearch']}>
            <Breadcrumbs {...breadcrumbs} inHero={true} />

            <Hero {...hero} />

            {!_.isEmpty(highlights) &&
                <Highlights {...highlights} />
            }

            <Modules {...modules} initialSize="Large" />
        </div>
    );
};

LandingSearch.propTypes = {
    breadcrumbs: PropTypes.object,
    hero: PropTypes.object,
    highlights: PropTypes.object,
    modules: PropTypes.object,
};

LandingSearch.defaultProps = {
    breadcrumbs: {},
    hero: {},
    highlights: {},
    modules: {},
};

export default Base(LandingSearch);
